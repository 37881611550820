nav{
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display : flex ;
    gap: 1.5rem;
    border-radius: 3rem;
    padding: 0.5rem 1.4rem ;
    box-shadow: 10px 10px 30px rgba(0,0,0,0.5);
    background-color: rgba(255, 255, 255, 0.05);
    border:0.5px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    z-index: 100;
}

.nav-link{
    color: white ;
    padding: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem ;
    background: transparent ;
    font-size: 1.3rem;
    backdrop-filter: blur(14px);
}

/* .active-link{
    color: black ;
    background: wheat;
 
} */

nav a:active  , nav a:hover ,.active-nav {
    color: black ;
    background: wheat;
    border-radius: 3rem;

}