/* .services {
  margin: 2rem 0;
  width: 100%;
} */



.service_container h4 ,.services_title h4 {
    margin: 2rem auto 2rem ;
    color:wheat;
    line-height: 2;
    width: 70%;
    font-size: 0.9rem;
}

.service_container{
    text-align: center;
    margin: 3rem auto 5rem;
}

.service-section{
    display: grid;
    grid-template-columns:25% 75% ;
    align-items: flex-start;
}

.available_services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;

}

.service-widget{
    display: grid;
    justify-items: center;
    row-gap: 1.4rem;
    box-shadow: 8px 8px 8px black , -8px -8px 8px #292727 ;
    color: #ecf0f1 ;
    border-radius: 2.5rem;
    margin: 2rem;
    transition: var(--transition);
    padding: 2rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.service-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
}

.service-icon{
    font-size: 4rem;
    color: wheat;
}

.dev-title{
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 0.5rem ;
    position: relative;
}

.selected-dev{
    color: var(--color-secondary);
}

.rectangle {
    position: absolute;
    margin-top: 0.7rem;
    transform: rotate(45deg) ;
    bottom: -20px;
    right: 5rem;
    width: 36px;
    height: 36px;
    background-color: black;
    box-shadow: 8px 8px 8px #292727 ;
    border-radius: 6px;
    z-index: 7;
}



.service-data {
    position: absolute;
    right: 2rem;
    left: 2rem;
    box-shadow:  3px 3px 3px #292727 ;
    color: #ecf0f1 ;
    border-radius: 1rem;
    transition: var(--transition);
    padding: 2rem;
    border: 0.5px solid var(--color-tertiary) ;
    background-color: var(--color-bg);
    z-index: 5;
    transition: all 0.5s ease-in-out;
  }
  
  .service-data-enter {
    animation: slide-down 0.4s ease-in-out forwards;
    display: block;
  }
  
  .service-data-exit {
    animation: slide-up 0.4s ease-in-out forwards;
    display: none;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-25px);
    }
    to {
      transform: translateY(0%);
    }
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(-0%);
    }
    to {
      transform: translateY(25px);
    }
  }

.hide-data {
    display: none;
    transition: all 0.5s ease-in-out;
}

.service-data h5{
    font-size: 0.9rem;
    text-align: start;
    font-weight: 500; 
    line-height: 2; 
}

.service-widget:hover{
    color: var(--color-secondary);
}

.softwares{
    margin: 1.5rem 0 0 0  ;
    min-height: max-content;
    display: flex;
    gap: 1.5rem ;
    font-size: 1.4rem;
    flex-wrap: wrap;
}


.software-alignment{
    display: flex;
    justify-content:space-between;
    align-items: center;
    column-gap: 1rem;
}

.close-data{
    color: var(--color-primary);
    font-size: 1rem;
    font-weight: bold;
    margin: 0.8rem;
    animation: slide-up 0.4s ease-in-out forwards;
}

.close-data:hover {
    cursor: pointer;
    font-size: 1.1rem;

}


@media screen and (max-width : 1300px){
      .available_services {
        grid-template-columns: 1fr 1fr;
      }

      .available_services > .service-widget:nth-child(3) {
        grid-column: span 2;
      }

      .service-section{
        display: grid;
        grid-template-columns:35% 65% ;
        align-items: center;
    }
}

@media screen and (max-width : 950px ) {
    .available_services {
        grid-template-columns: 1fr;
    }
    .available_services > .service-widget:nth-child(3) {
        grid-column: span 1;
      }

      .service-section{
        display: grid;
        grid-template-columns:45% 55% ;
        align-items: center;
    }
}

@media screen and (max-width : 700px ){
     .service-section{
        grid-template-columns: 1fr;
     }

     .service-lottie {
        display: flex;
        height: 350px;
        justify-content: center;
        margin: 0;
        padding: 0;
     }

     .service-widget{
        margin: 2rem 3rem;
     }

}

@media screen and (max-width : 450px ){

    

     .service_container h4 ,.services_title h4 {
        color:wheat;
        line-height: 2;
        width: 88%;
        font-size: 0.9rem;
    }

    .service-widget{
        padding: 1.5rem;
        border-radius: 2rem;
        margin: 2rem 1.3rem ;
    }

    .service-icons-container{
        column-gap: 1.2rem;
    }

    .service-icon{
        font-size: 3rem;
    }

    .dev-title{
        font-size: 1.2rem;
    }

    .rectangle {
        width: 28px;
        height: 28px;
        bottom: -15px;
        border-radius: 3px;
    }

    .service-data{
        left:0;
        right:0;
        padding: 1.5rem;
    }


}




