

.loading-spinner{
    margin: 0.5rem auto ;
    width: 30px;
    height: 30px;
    position: relative;
    animation: animateBg 10s linear infinite;
}

@keyframes animateBg {
    0% {
        filter: hue-rotate(0deg);
        }
    100% {
        filter: hue-rotate(360deg);
        }
    
}

.loading-spinner span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform : rotate(calc(18deg*var(--i)));
    /* background-color: aqua; */
}

.loading-spinner span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    border-radius: 50%;
    height: 4px;
    background: whitesmoke;
    box-shadow: 0 0 2px  whitesmoke ,
                /* 0 0 2px  whitesmoke, */
                0 0 2px  whitesmoke ;
    animation: animate 2s linear infinite;
    animation-delay: calc(0.1s*var(--i));
}

@keyframes animate {
    0% {
        transform: scale(1);
    }
    80%,100% {
        transform: scale(0);
    }
}
