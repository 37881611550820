.contacts-container{
    margin: 3rem auto;
}

.contacts-heading{
    text-align: center;
    line-height: 2;
}

.contacts-heading h5{
    font-size: 0.8rem;
    
}

.contacts-section {
    margin: auto 3rem ;
    display: grid;
    grid-template-columns: 35% 40% ;
    column-gap: 5rem;
    align-items: center;
}

.sent-msg-section{
    margin: 3rem auto ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sent-email { 
    margin: 0 auto;
    width: 20%;
    height: 20%;
}

.sent-info h3{
    margin: 1rem auto;
    text-align: center;
}



form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-input {
    margin: 1.7rem auto;
    position: relative;
}

input , textarea {
    border-radius: 10px;
    width: 100%;
    padding: 0.8rem 1.2rem;
    border: 1px solid whitesmoke;
    background: var(--color-bg);
    font-size: 1rem;
    line-height: 1.4;
    color: wheat;
    font-weight: 500 ;
}

textarea {
    height: 15rem;
    resize: none;
}

/* testing */
label {
    position: absolute;
    left: 0;
    pointer-events: none;
    font-size: 1rem;
    padding: 0.6rem 1rem ;
    color: rgba(255, 255, 255, 0.555) ; 
}

 .form-input input:not([value=""]) ~ label , 
 .form-input input:focus ~label ,
 .form-input textarea:valid ~ label , 
 .form-input textarea:focus ~label {
    color: var(--color-primary);
    transform: translate( 10px, -0.5rem );
    background: var(--color-bg) ;
    padding: 0 0.6rem ;
    font-size: 0.65rem;
    font-weight: bold;
}

.form-input input:focus , 
.form-input input:not([value=""])  , 
.form-input textarea:valid,
.form-input textarea:focus {
    border: 2px solid var(--color-primary) ;
}

.error-style {
    margin: 0.5rem 1rem ;
    font-size: 0.8rem;
    color: var(--color-secondary);

}

.no-error {
    display: none;
}

.show-error {
    display: block;
}

form button {
    margin: 0.5rem auto ;
    padding: 0.7rem 8rem;
    border-radius: 0.6rem ;
    box-shadow: 4px 4px 4px black  ;
    color: var(--color-bg);
    background-color: var(--color-primary);
    font-weight: bold;
    font-size: 1.2rem;
    transition: var(--transition);
}

form button:hover {
    box-shadow: 4px 4px 4px transparent  ; 
}


@media screen and (max-width : 1300px) {

    .contacts-section{
        grid-template-columns: 35% 50% ;
        column-gap: 5rem;
    }

    
}

@media screen and (max-width : 950px) {

    .contacts-section{
        margin: 0;
        grid-template-columns: 35% 50% ;
        column-gap: 3rem;
    }

    form button {
        padding: 0.5rem 6rem;
    }

    .sent-email { 
        margin: 0 auto;
        width: 25%;
        height: 25%;
    }
}

@media screen and (max-width : 700px) {

    
    .contacts-lottie{
        display: none;
    }

    .contacts-section{
        margin: 0;
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr ;

        column-gap: 3rem;
    }

    .sent-email { 
        margin: 0 auto;
        width: 50%;
        height: 50%;
    }

    form button {
        padding: 0.5rem 4rem;
    }
}