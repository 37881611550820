@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');



*{
    margin : 0 ;
    padding: 0;
    border:0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}


:root {
    --color-bg : #131419 ;
    --color-bg-variant : rgba(0, 0, 0, 0.573);
    --color-primary : rgb(109, 206, 239) ;
    --color-primary-variant: black ;
    --color-secondary: rgb(246, 172, 83) ;
    --color-tertiary: wheat;
    --color-white: #fff;
    --color-light:rgba(255,255,255,0.6);

    --container-width-lg: 75% ; 
    --container-width-md: 86%;
    --container-width-sm:90%;

    --transition :color 400ms ease-in
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: "Poppins" , sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    margin: 1.5rem 3rem;
}

h2{
    font-size: 2.5rem;
}

@media screen and (max-width : 1200px) {
    body{
        margin: 1.5rem;
        
    }
}



