
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.home-container{
    width: 100%;
    margin-bottom: 4rem;
    display :grid ;
    grid-template-columns: 50% 1fr;
}

.dev-lottie {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}

h1{
    margin-top: 1rem;
    font-size: 2.6rem;
    line-height: 3.5rem;
    font-weight: 400;
    font-family: 'Permanent Marker', 'cursive';
     /* box-shadow: 10px 10px 30px rgba(0,0,0,0.5); */
}

h1 span {
    color: wheat ; 
}

.title h5{
    color: var(--color-secondary) ;
    font-weight: bold;
}

.type-writer-container{
    margin : 4rem auto 4rem;
    color: white;

}

.title h4{
    font-size: 1rem;
    margin: 0.8rem auto;
}

.title h4 span{
    font-size: 1.15rem;
    margin-left: 0.3rem  ;
    color: rgb(245, 202, 129);
    font-family: 'Permanent Marker', 'cursive';
}


.type-writer-container h2 {
    color: white;
    font-size: 1.5rem;
}

.type-writer-container h2 span {
    font-size: 1.7rem;
    color: var(--color-primary);
}

.type-writer-container .static-text {
    font-size: 1.8rem;
    color: rgb(244, 205, 131);
    margin-left: 0.4rem;
    font-family: 'Permanent Marker', 'cursive';
}

.ourstyle-article{
    width: 125px ;
    display: flex;
    margin: 1rem ;
    margin-left: 0;
    row-gap: 0.8rem;
    padding: 0.7rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 10px 10px 30px rgba(0,0,0,0.5);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    
}

.ourstyle-icon{
    padding: 0.4rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.208);
    color: var(--color-primary) ;
    font-size: 2.5rem;
    box-shadow: 80px 0 2px  whitesmoke ,
    /* 0 0 2px  whitesmoke, */
}

.ourstyle-article h5 {
    color:var(--color-secondary);
    font-size: 0.8rem;   
}

.handle-our-style {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
}

.contact-button {
    display: block;
    padding: 0.7rem 1.2rem;
    margin: 2rem 3rem ;
    width: 70%;
    border: 1.5px solid  var(--color-primary);
    text-align: center;
    font-size: 1.2rem;
    border-radius: 0.8rem;
    color: var(--color-primary);
    font-weight: bold;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.5);
    transition: var(--transition);
}

.contact-button:hover{
    background-color: var(--color-primary);
    color: var(--color-bg);
    cursor: pointer;
}


@media screen and (max-width : 1350px ){
    .ourstyle-article{
        width: 110px;
        box-shadow:7px 7px 20px rgba(0,0,0,0.5);
    }

    .ourstyle-icon{
        font-size: 1.8rem;
    }

    .ourstyle-article h5 {
        font-size: 0.8rem;
        padding: auto 0.5rem;
    }

    .handle-our-style {
        justify-content: center;
        align-items: center;
    }
}


@media screen and (max-width : 700px ) {

    .type-writer-container{
        margin : 2rem auto;
    
    }
    .home-container {
        position: relative;
        display: block;
    }
    
    
    .dev-lottie {
        position: absolute;
        left :0;
        top : 0 ;
        opacity: 0.07;
        z-index: 3;
    }

    .contact-button{
        width: 85%;
        margin: 2rem auto;
    }
}

@media screen and (max-width : 480px ) {
    .ourstyle-article{
        width: 130px;
        margin: 0;
    }
    
    .ourstyle-icon{
        font-size: 1.5rem;
    }

    
    .ourstyle-article h5 {
        font-size: 0.8rem;
    }
    
    .handle-our-style {
        display: grid;
        grid-template-columns: auto auto;
        justify-items: center;
        align-items: stretch;
        row-gap: 2rem;
        column-gap: 2rem;
    }
    
    .type-writer-container h2 {
        font-size: 1.2rem;
    }
    
    .type-writer-container h2 span {
        font-size: 1.25rem;
    }

    .type-writer-container .static-text {
        font-size: 1.25rem;
        margin-left: 0.3rem;
    }

     


}